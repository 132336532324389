/* Footer list Box */
/* Footer list Box */
.footer-parent .footer-links-container .footer-list-box {
  margin: 0 30px;
}

.footer-parent .footer-links-container .footer-list-box ul {
  margin: 0;
  padding: 0;
}
.footer-parent .footer-links-container .footer-list-box ul li {
  list-style: none;
  margin: 7px 0;
  cursor: pointer;
}

/* Footer list Box */
/* Footer list Box */
