.landing-parent {
  position: relative;
  background-color: #000828;

  overflow: hidden;
  max-width: 2000px;
  margin: auto;
  margin-bottom: -120px;
  height: 100vh;
}

.landing-video {
  position: absolute;
  width: 100%;
  top: 20px;
  z-index: 1;
}

/* Landing Content */
/* Landing Content */
.landing-content {
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 60px;
  padding-top: 150px;
}

.landing-content .landing-header-text-bg {
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 115px;
  margin: 0;
  margin-bottom: 0px;
  margin-bottom: -130px;
}

.landing-content .landing-header-text {
  position: relative;
  font-size: 60px;
  text-align: center;
  background: -webkit-linear-gradient(#9747ff, #25a3fb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-content .landing-header-text span {
  color: #a966ff;
}

.landing-content .landing-header-text span:last-child {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #a867ff;
}

.landing-content .landing-text {
  color: #fff;
  margin-top: -35px;
}

.landing-content-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: space-evenly;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
}
.landing-content-btn::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(45deg, #9747ff, #fff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: add, add;
  mask-composite: exclude;
  pointer-events: none;
}

.colored-box {
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, #110321, #000828);
}

/* Animate Arrow in btn */
.landing-content-btn div {
  margin-top: -5px;
  animation-name: arrow-icon-movement;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes arrow-icon-movement {
  50% {
    margin-top: 10px;
  }
  100% {
    margin-top: -5px;
  }
}

/* Animate Arrow in btn */

/* Landing Content */
/* Landing Content */

/* Loop infinit text */
.text-line-scroll {
  position: absolute;
  z-index: 9999;
  background-color: #000828;
  white-space: nowrap;
  height: 35px;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
}

.text-line-scroll div {
  display: flex;
  gap: 2em;
  transform: translateX(0%);
  animation: rightToLeft 10s infinite linear;
  width: 100%;
}

.text-line-scroll div a {
  color: #fafffe73;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 20px;
}

/* @keyframes rightToLeft {
  100% {
    transform: translateX(-50%);
  }
} */

/* Loop infinit text */

/* Media Screens */
@media (max-width: 600px) {
  .landing-parent {
    height: 100vh;
  }
  .landing-content .landing-header-text {
    font-size: 30px;
  }

  .landing-content .landing-header-text-bg {
    font-size: 70px;
    margin-bottom: -60px;
  }

  .landing-content .landing-text {
    margin-top: -20px;
    font-size: 15px;
    text-align: center;
  }
  .landing-content-btn {
    width: 125px;
    font-size: 12px;
  }

  .landing-video {
    height: 100%;
  }
}

/* Media Screens */
