.about-parent {
  position: relative;
  padding: 50px 50px;
  background-color: #000828;
  padding-bottom: 30px;
  max-width: 2000px;
  margin: 100px auto 0 auto;
  z-index: 5;
}

/* .about-parent::before {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 694px;
  right: 0px;
  width: 150px;
  height: 251px;
  background-size: cover;
  z-index: -1;
} */

/* .about-parent::after {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 330px;
  left: 0px;
  width: 150px;
  height: 251px;
  background-size: cover;
  transform: scaleX(-1);
  z-index: -1;
} */

.first-about-container,
.last-about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.about-text {
  width: 50%;
}

.about-text .about-text-box {
  margin: 30px 0;
}

.about-text .about-text-box h4 {
  font-size: 30px;
}

.about-text h4 {
  font-size: 40px;
  margin: 0;
  color: #fff;
}

.about-text h4 span {
  color: #9747ff;
}

.about-text h4 span:last-child {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #9747ff;
}

.about-text p {
  font-size: 15px;
  margin: 0;
  color: #aaaaaa;
}

.about-img {
  position: relative;
}

.about-img::after {
  position: absolute;
  content: "";
  width: 104px;
  height: 104px;
  right: -22px;
  top: -22px;
  border-right: 22px solid #94ffe3;
  border-top: 22px solid #94ffe3;
  box-shadow: 3px -1px 5px 1px rgb(255, 255, 255);
}

.about-img img {
  width: 462px;
  height: 391px;
  z-index: 9999;
  position: relative;
}

/* Media Queries */
@media (max-width: 900px) {
  .about-text {
    width: 50%;
  }
  .about-text h4 {
    font-size: 26px;
  }

  .about-text p {
    font-size: 15px;
  }

  .about-img img {
    width: 300px;
    height: 270px;
    z-index: 9999;
    position: relative;
  }
}

/* Lights */
.lights1 {
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: #96fee5;
  top: 50px;
  right: 50px;
  border-radius: 50%;
  filter: blur(60px);
}

.lights2 {
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: #96fee5;
  top: 50px;
  right: 50px;
  border-radius: 50%;
  filter: blur(60px);
}

/* Lights */

/* TEXT LINE */
/* TEXT LINE */
.about-parent .rfm-marquee-container a {
  text-decoration: none;
  color: rgba(250, 255, 254, 0.3);
  margin-right: 30px;
}

/* TEXT LINE */
/* TEXT LINE */

@media (max-width: 768px) {
  .about-parent {
    padding: 50px 20px;
  }

  .first-about-container,
  .last-about-container {
    flex-direction: column;
  }

  .first-about-container {
    flex-direction: column-reverse;
  }

  .about-text {
    width: 100%;
  }

  .about-text h4 {
    margin: 10px 0;
  }

  .about-text p {
    font-size: 15px;
    margin-bottom: 40px;
  }

  .about-img {
    margin-bottom: 20px;
  }

  .about-img img {
    width: 300px;
    height: 270px;
  }
}

/* Media Queries */
