.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin: 0;
}

.card-container a {
  text-decoration: none;
  width: 100%;
}

.card-container .service-card-img-box {
  width: 75%;
  overflow: hidden;
  height: auto;
  margin: auto;
}

.card-container img {
  width: 100%;
  min-height: 100%;
}

.card-container .card-content h5 {
  margin: 0;
  margin-top: 0px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
.card-container .card-content p {
  margin: 0;
  font-size: 15px;
  color: #ffffff;
}

@media (max-width: 1200px) {
  .card-container .card-content {
    margin-top: -25%;
    margin-bottom: 40px;
  }

  .card-container .card-content h5 {
    font-size: 15px;
    margin-top: 60px;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }

  .card-container .service-card-img-box {
    /* max-height: 200px; */
  }
}

@media (max-width: 768px) {
  .card-container .card-content h5 {
    margin-top: 100px;
  }
}

@media (max-width: 600px) {
  .card-container {
    margin-left: -20%;
  }
  .card-container .service-card-img-box {
  }
  .card-container .card-content h5 {
    font-size: 12px;
    margin-top: 130px;
  }
  .card-container .card-content p {
    font-size: 12px;
    margin-top: 10px;
  }
}
