.services-page-parent {
  overflow: hidden;
  background-color: #000828;
  padding-top: 80px;
}

.services-page-parent .services-page-section {
  position: relative;
}

.services-page-parent .services-page-section::after {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 85px;
  right: 0%;
  width: 159px;
  height: 295px;
  background-size: cover;
  z-index: -1;
}
.services-page-parent .services-page-section::before {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 350px;
  left: 0%;
  width: 159px;
  height: 295px;
  background-size: cover;
  z-index: -1;
  transform: scaleX(-1);
}

.services-page-landing {
  position: relative;
  height: calc(100vh - 92px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-page-landing::after {
  position: absolute;
  content: "";
  width: 400px;
  height: 400px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-image: linear-gradient(to right, #9747ff, transparent 17%);
  border-radius: 50%;
  z-index: 500;
  animation-name: circle-rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}
@keyframes circle-rotate {
  100% {
    transform: translate(50%, -50%) rotate(360deg);
  }
}

.services-page-landing h1 {
  font-size: 50px;
  color: #9747ff;
  text-align: center;
}

/* Animation Lines */
/* Animation Lines */
.services-page-landing .line {
  position: absolute;
  width: 2px;
  background: rgb(151, 71, 255);
  background: linear-gradient(180deg, rgb(151, 71, 255) 49%, transparent 100%);
  border-radius: 7px;
  transition: 0.5s all linear;
}

.services-page-landing .line-small {
  top: 50px;
  right: 60px;
  height: 130px;

  animation: small-line-movement 1s 1s 1;
  animation-fill-mode: forwards;
}

@keyframes small-line-movement {
  100% {
    top: -150px;
  }
}

.services-page-landing .line-medium {
  top: 120px;
  right: 120px;
  height: 180px;

  animation: medium-line-movement 1s 1.2s 1;
  animation-fill-mode: forwards;
}

@keyframes medium-line-movement {
  100% {
    top: -180px;
  }
}
.services-page-landing .line-larg {
  top: 180px;
  left: 120px;
  height: 220px;

  animation: larg-line-movement 1s 1.3s 1;
  animation-fill-mode: forwards;
}

@keyframes larg-line-movement {
  100% {
    top: -250px;
  }
}

/* Animation Lines */
/* Animation Lines */

/* HEADER IMG AND TEXT */
/* HEADER IMG AND TEXT */

.services-page-parent .img-desc-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 180px;
  margin-top: 15px;
}

.services-page-parent .img-desc-box::after {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 85px;
  right: -50%;
  width: 159px;
  height: 295px;
  background-size: cover;
  z-index: -1;
}
.services-page-parent .img-desc-box::before {
  content: "";
  position: absolute;
  background-image: url("../../assests/decoration.png");
  top: 85px;
  left: -50%;
  width: 159px;
  height: 295px;
  background-size: cover;
  z-index: -1;
  transform: scaleX(-1);
}

.services-page-parent .img-desc-box .img-container {
  margin-bottom: -50px;
}

.services-page-parent .img-desc-box p {
  color: #fff;
  text-align: center;
}

/* HEADER IMG AND TEXT */
/* HEADER IMG AND TEXT */

/* Extra Images in Page */
/* Extra Images in Page */
.services-page-parent .extra-images-container {
  width: 80%;
  margin: 50px auto;
  margin-top: 150px;
}
.services-page-parent .extra-images-container img {
  width: 100%;
}
/* Extra Images in Page */
/* Extra Images in Page */

/* Services Pagination */
/* Services Pagination */
.services-page-parent .services-page-section .pagination-services-container {
  position: relative;
  display: flex;
  color: #fff;
  width: 40%;
  justify-content: space-between;
  margin: 100px auto;
}

.services-page-parent .services-page-section .pagination-services-container p {
  position: relative;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.5px;
  cursor: pointer;
}

.services-page-parent
  .services-page-section
  .pagination-services-container
  .active {
  color: #9747ff;
}

.services-page-parent
  .services-page-section
  .pagination-services-container
  .active::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 3px;
  background-color: #9747ff;
  border-radius: 7px;
  bottom: -1px;
  left: 0px;
}

/* Services Pagination */
/* Services Pagination */

@media (max-width: 600px) {
  .services-page-landing::after {
    width: 350px;
    height: 350px;
  }

  .services-page-parent .img-desc-box {
    width: 90%;
  }
  .services-page-parent .img-desc-box .img-container img {
    width: 100%;
  }
  .services-page-parent .img-desc-box .img-container {
    margin-bottom: -20px;
  }

  .services-page-parent
    .services-page-section
    .pagination-services-container
    p {
    margin-right: 15px;
  }
}
