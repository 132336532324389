.partners-parent {
  padding: 30px 50px;
  overflow: hidden;
  background-color: #000828;
  position: relative;
}
.partners-parent h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
}

.partners-parent h3 span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #9747ff;
}

/* Swiper style */
.partners-parent .swiper {
  min-height: 210px;
  width: 80%;
  margin: auto;
}

.partners-parent .swiper-pagination-bullet {
  background-color: #ffffff71;
}

.partners-parent .swiper-pagination-bullet-active {
  background-color: #9747ff !important;
}

.partener-img-container {
  width: 110px;
  height: 110px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border: 1px solid #9747ff;
  padding: 5px;
}

.partener-img-container img {
  width: 100%;
}

@media (max-width: 768px) {
  .partners-parent {
    padding: 0px 20px;
  }
  .partners-parent .swiper {
    width: 100%;
  }
  .partener-img-container {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 600px) {
  .partners-parent h3 {
    font-size: 30px;
  }
}
