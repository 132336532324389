.reference-parent {
  position: relative;
  padding: 50px;
  overflow: hidden;
  background-color: #000828;
}

.reference-parent h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
}
.reference-parent h3 span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #94ffe3;
}

/* Swiper box */
.reference-parent .swiper {
  min-height: 320px;
}

.reference-parent .testimonial-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reference-parent .testimonial-box .testimonial-description {
  max-width: 50%;
  text-align: center;
  font-size: 20px;
  color: #fff;
  line-height: 1.4;
}
.reference-parent .testimonial-box .testimonial-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reference-parent .testimonial-box .testimonial-rate p {
  margin: 0;
  font-size: 20px;
  color: #fff;
}

.reference-parent .swiper-pagination-bullet {
  background-color: #ffffff71;
}

.reference-parent .swiper-pagination-bullet-active {
  background-color: #9747ff !important;
}
/* Swiper box */

/* Media Queries */
@media (max-width: 900px) {
  .reference-parent .testimonial-box .testimonial-description {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .reference-parent::before {
    top: 96px;
    right: 0px;
    width: 109px;
    height: 200px;
  }
  .reference-parent::after {
    top: 300px;
    left: 0px;
    width: 109px;
    height: 200px;
  }
}

@media (max-width: 600px) {
  .reference-parent .swiper {
    min-height: 350px;
  }

  .reference-parent h3 {
    font-size: 30px;
  }

  .reference-parent .testimonial-box .testimonial-description {
    font-size: 15px;
  }
  .reference-parent .testimonial-box .testimonial-rate p {
    font-size: 15px;
  }

  .reference-parent .react-stars span {
    font-size: 20px !important;
  }

  .reference-parent .network-img-parent {
    top: 45% !important;
  }
  .reference-parent .network-img-parent img {
    height: 200px !important;
  }
}
