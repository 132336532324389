.choose-us-parent {
  position: relative;
  padding: 50px;
  max-width: 2000px;
  margin: auto;
  background-color: #000828;
}

.choose-us-parent h3 {
  color: #ffffff;
  font-size: 50px;
}

.choose-us-parent h3 span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #9747ff;
}

@media (max-width: 768px) {
  .choose-us-parent {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .choose-us-parent h3 {
    font-size: 30px;
  }

  .choose-us-parent .network-img-parent {
    top: 0% !important;
  }
  .choose-us-parent .network-img-parent img {
    height: 210px;
  }
}
