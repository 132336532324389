.services-parent {
  position: relative;
  padding: 40px 90px;
  background-color: #000828;
  max-width: 2000px;
  margin: auto;
}

.services-header-text h2 {
  text-align: center;
  font-size: 40px;
  color: #fff;
  margin: 0px 0 45px 0;
}

.services-header-text h2 span {
  color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #a867ff;
}

.services-parent .swiper-next-btn {
  position: absolute;
  top: 50%;
  right: 30px;
  z-index: 999999;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9747ff;
  border-radius: 50%;
  cursor: pointer;
  color: #9747ff;
  transform: translate(0%, 50%);
}
.services-parent .swiper-prev-btn {
  position: absolute;
  top: 50%;
  left: 70px;
  z-index: 999999;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9747ff;
  border-radius: 50%;
  cursor: pointer;
  color: #9747ff;
  transform: translate(0%, 50%);
}

.services-parent .swiper {
  width: 100%;
}

.services-parent .swiper-slide {
  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
}

@media (max-width: 600px) {
  .services-parent .swiper {
    width: 100%;
  }

  .services-parent {
    padding: 20px 0px;
    padding-bottom: 140px;
  }

  .services-header-text h2 {
    font-size: 30px;
    margin: 0px 0 20px 0;
  }

  .services-parent .swiper-next-btn {
    height: 45px;
    right: 25px;
    top: 80%;
    width: 45px;
  }
  .services-parent .swiper-prev-btn {
    height: 45px;
    left: 25px;
    top: 80%;
    width: 45px;
  }
}
