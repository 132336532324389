.light {
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: #7da098;
  top: 50px;
  right: 50px;
  border-radius: 50%;
  filter: blur(60px);
  opacity: 1;
  z-index: 9999999999999999999999999999;
}

@media (max-width: 600px) {
  .light {
    width: 100px;
    height: 100px;
  }
}
