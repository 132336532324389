.services-page-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 50px auto;
}

.services-page-container:first-child {
  margin-bottom: 50px;
}

.services-page-container .services-text {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 35px;
  text-decoration: none;
}

.services-page-container .services-text h4 {
  position: relative;
  font-size: 20px;
  margin: 0;
  color: #fff;
  margin-bottom: 6px;
}

.services-page-container .services-text h4 span {
  position: relative;
}

.services-page-container .services-text h4 span::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: #9747ff;
  top: 50%;
  left: -7px;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

/* this for coloring the text */
/* this for coloring the text */

/* 
.services-page-container .services-text h4 span {
  color: #9747ff;
}

.services-page-container .services-text h4 span:last-child {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #9747ff;
} */

/* this for coloring the text */
/* this for coloring the text */

.services-page-container .services-text .desc-container {
  display: flex;
  align-items: start;
  margin: 5px 0;
}

.services-page-container .services-text .desc-container .icon-container {
  margin-right: 6px;
}

.services-page-container .services-text .desc-container .icon-container img {
  width: 14px;
  height: 11px;
}

.services-page-container .services-text p {
  font-size: 15px;
  margin: 0;
  color: #aaaaaa;
}

.services-page-container .services-img {
  position: relative;
}

.services-page-container .services-img img {
  width: 414px;
  height: 341px;
  z-index: 9999;
  position: relative;
}

/* Media Queries */
@media (max-width: 900px) {
  .services-page-container .services-text {
    width: 50%;
  }
  .services-page-container .services-text h4 {
    font-size: 26px;
  }

  .services-page-container .services-text p {
    font-size: 15px;
  }

  .services-page-container .services-img img {
    width: 300px;
    height: 270px;
    z-index: 9999;
    position: relative;
  }
}

@media (max-width: 768px) {
  .services-page-container .about-parent {
    padding: 50px 20px;
  }

  .services-page-container {
    flex-direction: column;
  }

  .services-page-container .services-text {
    width: 100%;
  }

  .services-page-container .services-text h4 {
    margin: 10px 0;
  }

  .services-page-container .services-img {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .services-page-container .services-img img {
    width: 300px;
    height: 270px;
  }
}

/* Media Queries */
